import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.routes').then(m => m.routes),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/auth/login/login.page').then(m => m.LoginPage),
    data: { title: 'MASZK' },
  },
  {
    path: 'registration',
    loadComponent: () =>
      import('./pages/auth/registration/registration.page').then(m => m.RegistrationPage),
    data: { title: 'MASZK' },
  },
  {
    path: 'email-confirmation/:token',
    loadComponent: () =>
      import('./pages/auth/email-confirmation/email-confirmation.page').then(
        m => m.EmailConfirmationPage
      ),
    data: { title: 'MASZK' },
  },
  {
    path: 'reset-password/:token',
    loadComponent: () =>
      import('./pages/auth/reset-password/reset-password.page').then(m => m.ResetPasswordPage),
    data: { title: 'MASZK' },
  },
  {
    path: 'response/:id',
    loadComponent: () =>
      import('./pages/form-response/details/form-response-details.page').then(
        m => m.FormResponseDetailsPage
      ),
    canActivate: [AuthGuard],
    data: { title: 'MASZK' },
  },
  {
    path: '**',
    redirectTo: 'main',
    pathMatch: 'full',
  },
];
