import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { QueryParam } from '../../models/query-parameter.model';
import { OrderBy } from '../../models/order-by.model';
/* import { Observable, catchError, combineLatest, map, of } from 'rxjs';
import { QueryParam } from './../../models/query-parameter.model'; */

@Injectable({
  providedIn: 'root',
})
export class FBaseService {
  constructor(protected afs: AngularFirestore) {}

  async add(collectionName: string, data: any, id?: string) {
    const uid = id ? id : this.afs.createId();
    data.id = uid;
    await this.afs.collection(collectionName).doc(uid).set(data);
    return uid;
  }

  set(collectionName: string, id: string, data: any) {
    return this.afs.collection(collectionName).doc(id).set(data);
  }

  async update(collectionName: string, id: string, data: any) {
    return await this.afs.collection(collectionName).doc(id).update(data);
  }

  get(collectionName: string, userId?: string | null, orderBy?: OrderBy, limit?: any) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (userId) {
          query = query.where('author.reference', '==', userId);
        }
        if (orderBy) {
          query = query.orderBy(orderBy.active, orderBy.direction);
        }
        if (limit) {
          query = query.limit(limit);
        }
        return query;
      })
      .valueChanges();
  }

  getById(collectionName: string, id: string) {
    return this.afs.collection(collectionName).doc(id).valueChanges();
  }

  getByField(
    collectionName: string,
    limit: number | null = 24,
    startAt?: any,
    parentId?: string,
    parentPath?: string,
    opStr = '==',
    orderBy?: { active: string; direction: 'asc' | 'desc' }
  ) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (parentId && parentPath) {
          query = query.where(parentPath, opStr as any, parentId);
        }
        if (limit) {
          query = query.limit(limit);
        }
        if (orderBy?.active && orderBy?.direction) {
          query = query.orderBy(orderBy.active, orderBy.direction);
        } else {
          query = query.orderBy('id');
        }
        if (startAt) {
          query = query.startAfter(startAt);
        }
        return query;
      })
      .valueChanges();
  }

  getByMultiField(
    collectionName: string,
    limit = 24,
    queryParam?: QueryParam[],
    orderBy?: any,
    startAt?: any
  ) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (queryParam) {
          queryParam.forEach(e => {
            if (e.field && e.opStr) {
              query = query.where(e.field, e.opStr as any, e.value);
            }
          });
        }
        if (limit) {
          query = query.limit(limit);
        }
        if (Array.isArray(orderBy)) {
          orderBy.forEach(o => {
            if (o.active && o.direction) {
              query = query.orderBy(o.active, o.direction);
            }
          });
        } else {
          if (orderBy?.active && orderBy?.direction) {
            query = query.orderBy(orderBy.active, orderBy.direction);
          } else {
            query = query.orderBy('id');
          }
        }
        if (Array.isArray(startAt)) {
          query = query.startAfter(...startAt);
        } else if (startAt) {
          query = query.startAfter(startAt);
        }
        return query;
      })
      .valueChanges();
  }

  getSavedAnswers(collectionName: string, profileId: string, subCampaignId: string) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (profileId) {
          query = query.where('profileId', '==', profileId);
        }
        if (subCampaignId) {
          query = query.where('subCampaignId', '==', subCampaignId);
        }
        return query;
      })
      .valueChanges();
  }

  delete(collectionName: string, id: string) {
    return this.afs.collection(collectionName).doc(id).delete();
  }

  /*
  getForms(formIds: string[]): Observable<any> {
    const obsArray: Observable<any>[] = [];

    formIds.forEach(formId => {
      obsArray.push(
        this.getById('Forms', formId).pipe(
          catchError(error => {
            console.error('error loading the list', error);
            return of();
          })
        )
      );
    });
    return combineLatest(obsArray);
  }

  getSubCampaigns(subCampaignIds: string[]): Observable<any> {
    const obsArray: Observable<any>[] = [];
    subCampaignIds.forEach(subCampaignId => {
      obsArray.push(
        this.getById('SubCampaigns', subCampaignId).pipe(
          map((item: any) => (item?.data ? item.data : item)),
          catchError(error => {
            console.error('error loading the list', error);
            return of();
          })
        )
      );
    });
    return combineLatest(obsArray);
  }

  getSourceFResponse(
    collectionName: string,
    profileId: string,
    subCampId: string,
    targetDate?: any[]
  ) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (profileId) {
          query = query.where('author.reference', '==', profileId);
        }
        if (subCampId) {
          query = query.where('subCampaignId', '==', subCampId);
        }
        query = query.where('status', '==', 'filled');
        if (targetDate?.[0]) {
          query = query.where('filledDate', '<=', targetDate[0]);
        }
        if (targetDate?.[1]) {
          query = query.where('filledDate', '>=', targetDate[1]);
        }
        query = query.orderBy('filledDate', 'desc');
        query = query.limit(2);
        return query;
      })
      .valueChanges();
  }

  getByName(
    collectionName: string,
    name: string,
    limit: number = 1,
    fieldPath: string = 'name',
    opStr = 'array-contains',
    org?: string,
    parentPath = 'parentId'
  ) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (org) {
          query = query.where(parentPath, '==', org);
        }
        if (name) {
          query = query.where(fieldPath, opStr as any, name);
        }
        query = query.limit(limit);
        return query;
      })
      .valueChanges();
  }

  searchByValue(collection: string, field: string, value: string, limit: number, org?: string) {
    return this.afs
      .collection(collection, ref => {
        let query: CollectionReference | Query = ref;
        if (org) {
          query = query.where('parentId', '==', org);
        }
        query = query.orderBy(field);
        query = query.startAt(value);
        query = query.limit(limit);
        return query;
      })
      .valueChanges();
  }

  getByMultiField(
    collectionName: string,
    limit = 24,
    startAt?: any,
    queryParam?: QueryParam[],
    orderBy?: any
  ) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (queryParam) {
          queryParam.forEach(e => {
            if (e.field && e.opStr) {
              query = query.where(e.field, e.opStr as any, e.value);
            }
          });
        }
        if (limit) {
          query = query.limit(limit);
        }
        if (Array.isArray(orderBy)) {
          orderBy.forEach(o => {
            if (o.active && o.direction) {
              query = query.orderBy(o.active, o.direction);
            }
          });
        } else {
          if (orderBy?.active && orderBy?.direction) {
            query = query.orderBy(orderBy.active, orderBy.direction);
          } else {
            query = query.orderBy('id');
          }
        }
        if (Array.isArray(startAt)) {
          query = query.startAfter(...startAt);
        } else if (startAt) {
          query = query.startAfter(startAt);
        }
        return query;
      })
      .valueChanges();
  }

  async updateQuestions(
    collectionName: string,
    id: string,
    questions: any,
    status?: string,
    filledDate?: boolean
  ) {
    return await this.update(collectionName, id, {
      lastUpdated: new Date().toISOString(),
      questions: questions,
      status: status,
      filledDate: filledDate ? new Date().toISOString() : null,
    });
  }
 */
}
