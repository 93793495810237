export class LogItem {
  id: any;
  title: any;
  subtitle: any;
  type: any;
  uid: any;
  versionNumber: any;
  versionCode: any;
  packageName: any;
  cordova: any;
  platform: any;
  timeStamp: any;
  email: any;
  currentUrl: any;
  appName: any;
  constructor(
    id = null,
    title = null,
    subtitle = null,
    type = null,
    uid = null,
    versionNumber = null,
    versionCode = null,
    packageName = null,
    cordova = false,
    platform = null,
    timeStamp = null,
    email = null,
    currentUrl = null,
    appName = null
  ) {
    this.id = id;
    this.title = title ? (typeof title !== 'string' ? JSON.stringify(title) : title) : title;
    this.subtitle = subtitle
      ? typeof subtitle !== 'string'
        ? JSON.stringify(subtitle)
        : subtitle
      : subtitle;
    this.type = type;
    this.uid = uid;
    this.versionNumber = versionNumber;
    this.versionCode = versionCode;
    this.packageName = packageName;
    this.cordova = cordova;
    this.platform = platform;
    this.timeStamp = timeStamp || new Date().toISOString();
    this.email = email;
    this.currentUrl = currentUrl;
    this.appName = appName;
  }
}
