import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './../services/auth.service';
import { LoggerService } from './../services/logger.service';

export const AuthGuard = async () => {
  const authService = inject(AuthService);
  const logger = inject(LoggerService);
  const router = inject(Router);

  let user: any;

  try {
    user = await firstValueFrom(authService.currentUserObservable());
  } catch (error) {
    logger.handleError(error);
  }

  if (user) {
    return true;
  }

  return router.parseUrl('/login');
};
